import React, { FC, useState, useEffect, useCallback } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import BtnLikeVoyageIcon from "components/BtnLikeVoyageIcon/BtnLikeVoyageIcon";
import { VoyageDataType } from "data/types";
import Axios from '../../_services/caller.service';
import { voyageService } from '_services/voyage.service';
import { useTranslation } from 'react-i18next';
import AvatarMenu from "shared/AvatarMenu/AvatarMenu";
import { Tooltip }  from "react-tooltip";
import 'react-tooltip/dist/react-tooltip.css';

export interface CardVoyageFavoriProps {
  className?: string;
  voyageData?: VoyageDataType;
  onMajLike?: (id: number) => void;
  onMajDislike?: (id: number) => void;
}

const CardVoyageFavori: FC<CardVoyageFavoriProps> = ({
  className = "",
  voyageData,
  onMajLike,
  onMajDislike
}) => {
  const { t } = useTranslation();
  
  const [photo, setPhoto] = useState("");
  const [nbLikes, setNbLikes] = useState<number>(voyageData?.nb_likes || 0);
  const [imagesAvatars, setImagesAvatars] = useState<string[]>([]);
  
  useEffect(() => {
    let imageURL: string | undefined;

    if (voyageData && voyageData?.couverture && voyageData?.couverture !== "") {
      Axios.get(voyageData?.couverture || "", { responseType: 'blob' })
        .then((response) => {
          const blob = new Blob([response.data], { type: 'image/jpeg' });
          imageURL = URL.createObjectURL(blob);
          setPhoto(imageURL);
        })
        .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
    } else {
      let imageCouv = require(`../../images/countries/${voyageData?.id_pays.split("_")[0]}.webp`);
      setPhoto(imageCouv);
    }

    if(voyageData && voyageData.identifiant && voyageData.identifiant !== "")
    {

      if (typeof voyageData.identifiant === 'string') {
        const identifiantList = voyageData.identifiant.split(";");

     identifiantList.forEach((id: any) => {
         Axios.get('/fichiers/avatar/'+id, { responseType: 'blob' })
             .then((response) => {
                 const blob = new Blob([response.data], { type: 'image/jpeg' });
                 const imageURL = URL.createObjectURL(blob);
                 
                 setImagesAvatars(prevImages => [...prevImages, imageURL]);
             })
             .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
           });
      }

      } else {
        if(voyageData && voyageData.identifiant && voyageData.identifiant !== "")
        {
          Axios.get('/fichiers/avatar/'+voyageData.identifiant, { responseType: 'blob' })
          .then((response) => {
              const blob = new Blob([response.data], { type: 'image/jpeg' });
              const imageURL = URL.createObjectURL(blob);
              
              setImagesAvatars(prevImages => [...prevImages, imageURL]);
          })
          .catch((error) => console.error('Erreur lors du chargement de l\'image', error));
        }
      }
     
    return () => {
      if (imageURL) {
        URL.revokeObjectURL(imageURL); // Révoquez l'URL seulement lorsque le composant est démonté
      }
    };
  }, []);


   const handleUsernameClick = (e: React.MouseEvent<HTMLSpanElement>, index: number) => {
    console.log("handleUsernameClick");


      e.preventDefault();
      e.stopPropagation();
    
     // Ouvrir le lien dans un nouvel onglet
    if (voyageData && voyageData.identifiant) { 
      if (typeof voyageData.identifiant === 'string') {
        const identifiants = voyageData.identifiant.split(';');
        const selectedIdentifiant = identifiants[index]?.trim();
        if (selectedIdentifiant) {
          window.open(`/voyageur/${selectedIdentifiant}`, '_blank');
        }
      } else {
        window.open(`/voyageur/${voyageData.identifiant}`, '_blank');
      }
      
    }
    };

  const handleLinkClick = (event: any) => {
    event.stopPropagation();
  };

  // Fonction pour obtenir la valeur d'un cookie
  const getCookie = (name: string) => {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith(name))
      ?.split('=')[1];
    return cookieValue || '';
  };

  const [isLiked, setIsLiked] = useState(() => {
    if (voyageData?.liked === 'O') {
      return true;
    } else {
      // Vérifie si le cookie existe
      const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
      const cookieValue = getCookie(cookieName);

      // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
      if (cookieValue === 'O') {
        return true;
      }
    }
    return false;
  });

  useEffect(() => {
    if (voyageData?.nb_likes) {
      setNbLikes(voyageData?.nb_likes);
    }

    if (voyageData?.liked && voyageData?.liked === 'O') {
      setIsLiked(true);
    } else {
      // Vérifie si le cookie existe
      const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
      const cookieValue = getCookie(cookieName);

      // Si le cookie existe et a la valeur 'O', met à jour isLiked à true
      if (cookieValue === 'O') {
        setIsLiked(true);
      } else {
        setIsLiked(false);
      }
    }
  }, [voyageData?.nb_likes]); 

  // Fonction de gestion du clic sur le bouton like
  const handleLikeClick = useCallback(async (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    
    var etatLike = isLiked;

    if (!etatLike) {
      // Ajout du cookie
      const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
      document.cookie = `${cookieName}=O; path=/`;

      const xsrfToken = localStorage.getItem('xsrfToken');

      if (onMajLike) {
        onMajLike(voyageData?.id_voyage || 0);
      }

      try {
        const response = await voyageService.likeVoyage({ id_voyage: voyageData?.id_voyage }, {
          headers: {
            'X-XSRF-Token': xsrfToken,
          },
        });
      } catch (error: any) {
        console.error("Error like voyage :", error);
      }
    } else {
      // Suppression du cookie
      const cookieName = `tripkygoLike${voyageData?.id_voyage}`;
      document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/`;

      const xsrfToken = localStorage.getItem('xsrfToken');

      if (onMajDislike) {
        onMajDislike(voyageData?.id_voyage || 0);
      }

      try {
        const response = await voyageService.dislikeVoyage(voyageData?.id_voyage, {
          headers: {
            'X-XSRF-Token': xsrfToken,
          },
        });
      } catch (error: any) {
        console.error("Error dislike voyage:", error);
      }
    }
  }, [isLiked, voyageData?.id_voyage]);

  const paysList = voyageData?.libelles_pays?.split('_') || [];
  const idPaysList = voyageData?.id_pays?.split('_') || [];

  return (
    <div
      className={`nc-CardVoyageFavori relative flex items-center pt-3 pb-2 pl-3 pr-3 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardVoyageFavori"
    >
      <BtnLikeVoyageIcon nbLikes={nbLikes} isLiked={isLiked} classCoeur="text-xxs miniCoeur" taille="h-3 w-3" className="absolute right-2 top-2 z-[1]" onLikeClick={handleLikeClick} />
      
      <Link
        to={`${t('lienPlanificateur')}/${voyageData?.id_voyage}`}
        className="relative flex-shrink-0 w-24 h-24 rounded-full overflow-hidden"
      >
        <NcImage key={voyageData?.id_voyage} src={photo} containerClassName="absolute inset-0" />
      </Link>

      <div className="ml-1 mt-4 flex-grow overflow-hidden text-center bottom-1">
        <Link
          to={`${t('lienPlanificateur')}/${voyageData?.id_voyage}`}
          className="pointeurRose font-medium text-xs lg:text-xs mt-2 mb-1 titreVoyageFavori"
        >
          <span style={{ lineHeight: '1rem', display: 'inline-block'  }}>{voyageData?.titre_voyage}</span>
        </Link>
        <div className="items-center text-center text-xs space-x-1">
          {paysList.map((pays, index) => (
            <React.Fragment key={`pays-container-${index}`}>
              <a
                href={`${t('lienGuide')}/${idPaysList[index]}`}
                className="mr-1 pointeurRose"
                target="_blank"
                rel="noreferrer"
                onClick={handleLinkClick}
                key={`pays-${index}`}
              >
                <u>{pays}</u>
              </a>
              {index < paysList.length - 1 && (
                <span key={`span-pays-${index}`} className="mr-1">-</span>
              )}
            </React.Fragment>
          ))}
        </div>
        <div className="items-end text-right text-xs space-x-1 mt-1">
        {voyageData?.username && (
        voyageData?.username.split(';').map((name, index) => (
          <span
          key={index}
          onClick={(e) => handleUsernameClick(e, index)}
          data-tooltip-id={`myTooltip${index}`} 
          data-tooltip-content={name}
          data-tooltip-place="bottom"
          className="avatarFavoris"
          style={{ cursor: 'pointer', display: 'inline-flex', alignItems: 'center' }}
        >
             <AvatarMenu sizeClass="w-6 h-6 sm:w-6 sm:h-6 mr-1" imgUrl={imagesAvatars[index] || ''} />
             <Tooltip id={`myTooltip${index}`} style={{ zIndex: 9999 }}></Tooltip>
           </span>
         ))
      )}
      </div>
        <span
          className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}
        >
        </span>
      </div>
    </div>
  );
};

export default CardVoyageFavori;
