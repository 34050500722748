import React, { FC, useState, useRef, useEffect } from "react";

import BtnOptionsIcon from "components/BtnOptionsIcon/BtnOptionsIcon";
import { useLocation} from "react-router-dom";
import { useTranslation } from 'react-i18next';

export interface EvenementBtns2Props {
  index: string;
  onBlocNotesClick?: (typeEvenement: string, idEvenement: number) => void;
  onConfirmClick?: (typeEvenement: string, idEvenement: number) => void;
  onInfoClick?: (placeId: string, placeNom: string, latitude: string, longitude: string) => void;
  
  onRechercheClick?: (typeEvenement: string, idEvenement: number) => void;
  lienVisible?: string;
  lienItineraire?: string;
  placeId?: string;
  placeNom?: string;
  latitude?: string;
  longitude?: string;
  className?: string;
  optionsVisibles?: boolean;
}

const EvenementBtns2:FC<EvenementBtns2Props> = ({
  index,
  onBlocNotesClick,
  onConfirmClick,
  onInfoClick,
  onRechercheClick,
  lienVisible,
  lienItineraire,
  placeId,
  placeNom,
  latitude,
  longitude,
  className="right-6 top-3 w-5 h-5",
  optionsVisibles
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  let tailleTexte="text-xs";
  if (location.pathname.startsWith("/itineraire-voyage-integre") || location.pathname.startsWith("/en/travel-itinerary-embed")) {
    tailleTexte="text-xxs";
  }

  const [isPopupVisible, setPopupVisible] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);

  
  const typeEvenement=index.split('-')[0];
  const idEvenement=Number(index.split('-')[1]);

  useEffect(() => {
    setPopupVisible(optionsVisibles || false);
  }, [optionsVisibles])

  const showPopup = () => {
    setPopupVisible(true);
  };

  const hidePopup = () => {
    setPopupVisible(false);
  };

  const handleMouseLeave: React.MouseEventHandler<HTMLDivElement> = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (!popupRef.current?.contains(e.relatedTarget as Node)) {
      setPopupVisible(false);
    }
  };

  const handleInfoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (onInfoClick && placeId && placeNom) {

      if(latitude && longitude)
      {
        onInfoClick(placeId, placeNom, latitude, longitude);
      }
      else
      {
        onInfoClick(placeId, placeNom, "", "");
      }

    }
  };

  const handleConfirmClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onConfirmClick && index) {
      onConfirmClick(typeEvenement, idEvenement);
    }
  };

  const handleBlocClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onBlocNotesClick && index) {
      onBlocNotesClick(typeEvenement, idEvenement);
    }
  };

  const handleRechercheClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    if (onRechercheClick) {
      onRechercheClick(typeEvenement, idEvenement);
    }
  };


  return (
    <div className="flow-root" onMouseOver={showPopup}
    onMouseLeave={handleMouseLeave}>

      <BtnOptionsIcon onMouseOver={showPopup}
          onMouseLeave={handleMouseLeave}
        className={className}/>

      {isPopupVisible && (onInfoClick || onConfirmClick || onBlocNotesClick || lienVisible || lienItineraire ) && (
        <div
          ref={popupRef}
          className="absolute  top-0 right-2 p-4 bg-white dark:bg-gray-800 rounded-md shadow-md mt-2 w-50"
          style={{ zIndex: 20 }}
        >
           { onRechercheClick && (
           <div className={`${tailleTexte} text-left pointeurRose`} onClick={handleRechercheClick}>
            <i className={`las la-search couleurRose text-sm `}></i> {t('recherche')}
          </div>
           )}
          { onBlocNotesClick && (
          <div className={`${tailleTexte} text-left pointeurRose`} onClick={handleBlocClick}>
            <i className={`las la-pencil-alt text-sm `}></i> {t('blocNotes')}
          </div>
          )}
          { onConfirmClick && (
          <div className={`${tailleTexte} text-left pointeurRose`} onClick={handleConfirmClick}>
            <i className={`las la-file-alt text-sm `}></i> {t('confirmation')}
          </div>
          )}
          { onInfoClick && (
          <div className={`${tailleTexte} text-left pointeurRose`} onClick={handleInfoClick}>
            <i className={`las la-info text-sm `}></i> {t('informations')}
          </div>
          )}
          { lienItineraire && (
          <div className={`${tailleTexte} text-left pointeurRose`}>
            <a href={lienItineraire} target="_blank"><i className={`las la-globe text-sm `}></i> {t('itineraire')}</a>
          </div>
          )}
          { lienVisible && (
          <div className={`${tailleTexte} text-left pointeurRose`}>
            <a href={lienVisible} target="_blank"><i className={`las la-link text-sm `}></i> {t('lien')}</a>
          </div>
          )}
        </div>
      )}
    </div>
  );
};

export default EvenementBtns2;
